<template>
  <v-dialog
    v-model="dialog"
    width="500"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>{{ mdiCloudDownload }}</v-icon>
      </v-btn>
    </template>
    <v-card elevation="0">
      <v-card-title>Import Data</v-card-title>
      <v-card-text>
        <v-autocomplete
          v-model="selectedJobListing"
          :items="jobListings"
          :loading="isLoading"
          item-text="name"
          label="From Job Listing"
          return-object
        />
        <v-autocomplete
          :disabled="!selectedJobListing"
          v-model="selectedCandidate"
          :items="candidates"
          item-text="name"
          label="From Candidate"
          return-object
        />
        <v-select
          :disabled="!selectedCandidate"
          :items="availablePrintData"
          v-model="selectedPrintData"
          label="Item"
        />
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="dialog = false">Close</v-btn>
        <v-spacer />
        <v-btn
          color="primary"
          :disabled="!selectedPrintData"
          @click="importData"
        >
          Import
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { sortBy } from 'lodash';
import { mdiCloudDownload } from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'ImportPrintData',
  props: {
    documentTemplateField: {
      type: Object,
      required: true,
    },
  },
  mixins: [loading],
  data() {
    return {
      dialog: false,
      file: null,
      mdiCloudDownload,
      selectedJobListing: undefined,
      selectedCandidate: undefined,
      selectedPrintData: undefined,
    };
  },
  computed: {
    ...mapState('jobListings', ['jobListings']),
    jobListingCandidates() {
      if (!this.selectedJobListing) {
        return [];
      }
      return this.selectedJobListing.jobListingCandidates;
    },
    printData() {
      if (!this.selectedCandidate) {
        return [];
      }
      this.$log.debug(this.selectedCandidate);
      const printData = JSON.parse(this.selectedCandidate.jobListing.printData || '{}');
      return printData;
    },
    availablePrintData() {
      if (!this.selectedCandidate) {
        return [];
      }
      return Object.keys(this.printData);
    },
    candidates() {
      this.$log.debug(this.jobListingCandidates);
      const res = sortBy(this.jobListingCandidates.map((j) => ({
        ...j.candidate,
        jobListing: j,
      })), 'name');
      this.$log.debug(res);
      return res;
    },
  },
  watch: {
    dialog(val) {
      if (!val) {
        this.selectedCandidate = undefined;
        this.selectedPrintData = undefined;
        this.selectedJobListing = undefined;
      } else {
        this.refresh();
      }
    },
  },
  methods: {
    ...mapActions('jobListings', ['LOAD_jobListingPrintData']),
    importData() {
      const { printData, selectedPrintData } = this;
      const value = printData[selectedPrintData];
      this.$log.debug({ printData, selectedPrintData, value });

      this.$emit('import', { printData, selectedPrintData, value });
      this.dialog = false;
    },
    async refresh() {
      this.isLoading = true;
      await this.LOAD_jobListingPrintData();
      this.isLoading = false;
    },
  },
};
</script>
