<template>
  <div>
    <v-dialog v-model="dialog" fullscreen>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          small
          :color="jobListingCandidate.printData?.length > 0 ? 'success' : 'primary'"
          outlined
          v-bind="attrs"
          v-on="on"
          @click="dialog = true"
        >
          <v-icon>{{ mdiPrinter }}</v-icon>
        </v-btn>
      </template>
      <v-card :loading="isLoading">
        <v-card-title>
          <v-icon class="mr-2">{{ mdiPrinter }}</v-icon>
          Document Templates
          <v-icon class="ml-auto" @click="dialog = false">{{ mdiClose }}</v-icon>
        </v-card-title>
        <v-card-text>
          <v-form style>
            <v-select
              :items="documentTemplates"
              item-text="name"
              item-value="id"
              v-model="templateId"
              label="Template"
              @change="loadDocumentTemplateFields(templateId)"
            />
            <v-row v-if="templateId">
              <v-col
                class="d-flx"
                cols="12"
              >
                <v-row dense>
                  <v-col cols="4">
                    <v-text-field
                      label="Name"
                      :value="jobListingCandidate.candidate.name"
                      disabled
                      outlined
                    />
                  </v-col>
                  <v-col cols="4">
                    <ModalInlineTextField
                      v-model="localJobListingCandidate.candidate.email"
                      label="Email"
                      @input="updateCandidate(localJobListingCandidate.candidate, 'email', $event)"
                    />
                  </v-col>
                  <v-col cols="4">
                    <ModalInlineTextField
                      v-model="localJobListingCandidate.candidate.phone"
                      label="Phone"
                      @input="updateCandidate(localJobListingCandidate.candidate, 'phone', $event)"
                    />
                  </v-col>
                  <v-col cols="4">
                    <ModalInlineTextField
                      v-model="localJobListingCandidate.candidate.dateOfBirth"
                      label="Date of Birth"
                      @input="updateCandidate(
                        localJobListingCandidate.candidate, 'dateOfBirth', $event,
                      )"
                    />
                  </v-col>
                  <v-col cols="4">
                    <ModalInlineTextArea
                      v-model="localJobListingCandidate.candidate.location"
                      label="Location"
                      @input="updateCandidate(localJobListingCandidate.candidate, 'location', $event)"
                    />
                  </v-col>
                  <v-col cols="4">
                    <ModalInlineSelect
                      v-model="localJobListingCandidate.candidate.country"
                      label="Country"
                      :items="countries"
                      @input="updateCandidate(localJobListingCandidate.candidate, 'country', $event)"
                    />
                  </v-col>
                  <v-col cols="4">
                    <router-link
                      :to="{
                        name: 'candidates-EditCandidate',
                        query: {
                          id: localJobListingCandidate.candidate.id,
                          jobListingId: jobListing.id,
                          jobListingName: jobListing.name,
                        },
                      }">
                      Candidate Photo:
                    </router-link>
                    <v-img height="128" contain :src="localJobListingCandidate.candidate.imageUrl" />
                  </v-col>
                  <v-col cols="4">
                    <router-link
                      :to="{
                        name: 'clients-EditClient',
                        query: {
                          id: jobListing.client.id,
                          jobListingId: jobListing.id,
                          jobListingName: jobListing.name,
                        },
                      }">
                      Client Logo:
                    </router-link>
                    <v-img
                      v-if="jobListing.client"
                      height="128"
                      contain
                      :src="jobListing.client.imageUrl"
                    />
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                class="d-flex"
                cols="12"
                v-for="documentTemplateField in documentTemplateFields"
                :key="documentTemplateField.id"
              >
                <v-textarea
                  v-if="documentTemplateField.fieldType === 'Text'"
                  :label="documentTemplateField.description"
                  :hint="documentTemplateField.hint"
                  v-model="printData[documentTemplateField.name]"
                  @change="updatePrintData"
                  outlined
                />
                <v-select
                  v-else-if="documentTemplateField.fieldType === 'Select'"
                  :items="documentTemplateField.options"
                  :label="documentTemplateField.description"
                  :hint="documentTemplateField.hint"
                  v-model="printData[documentTemplateField.name]"
                  @change="updatePrintData"
                  outlined
                />
                <FieldTable
                  v-else-if="documentTemplateField.fieldType === 'Table'"
                  v-model="printData[documentTemplateField.name]"
                  @change="updatePrintData"
                >
                  {{ documentTemplateField.description}}
                </FieldTable>
                <div class="ml-2" />
                <ImportPrintData
                  :documentTemplateField="documentTemplateField"
                  @import="importData" />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="dialog = false">Close</v-btn>
          <v-spacer v-if="templateId" />
          <v-btn
            v-if="templateId"
            color="primary"
            :href="url"
            @click="dialog = false"
          >
            Print
          </v-btn>
          <v-spacer v-if="templateId" />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { mdiClose, mdiPrinter, mdiPencil } from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import FieldTable from './FieldTable.vue';
import ImportPrintData from './ImportPrintData.vue';
import ModalInlineSelect from './ModalInlineSelect.vue';
import ModalInlineTextArea from './ModalInlineTextArea.vue';
import ModalInlineTextField from './ModalInlineTextField.vue';

export default {
  name: 'ModalPrint',
  components: {
    FieldTable,
    ImportPrintData,
    ModalInlineSelect,
    ModalInlineTextField,
    ModalInlineTextArea,
  },
  mixins: [loading],
  props: {
    jobListingCandidate: {
      type: Object,
      required: true,
    },
    jobListing: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      mdiClose,
      mdiPrinter,
      mdiPencil,
      dialog: false,
      documentTemplateFields: [],
      templateId: undefined,
      printData: JSON.parse(this.jobListingCandidate.printData || '{}'),
      localJobListingCandidate: this.jobListingCandidate,
    };
  },
  computed: {
    ...mapGetters('app', ['countries']),
    ...mapState('documentTemplates', ['documentTemplates']),
    url() {
      const { jobListingCandidate, templateId } = this;
      const baseUrl = process.env.VUE_APP_APIURL;
      return `${baseUrl}/print/job-listing/candidate/${jobListingCandidate.id}?templateId=${templateId}`;
    },
  },
  methods: {
    ...mapActions('documentTemplateFields', ['LOAD_documentTemplateFields']),
    ...mapActions('jobListingCandidates', ['UPDATE_jobListingCandidate']),
    ...mapActions('candidates', ['UPDATE_candidate']),
    async loadDocumentTemplateFields(templateId) {
      this.isLoading = true;
      this.$log.debug(`loading document template fields for: ${templateId}`);
      this.documentTemplateFields = await this.LOAD_documentTemplateFields(templateId);
      this.isLoading = false;
    },
    async importData({ printData, selectedPrintData, value }) {
      this.isLoading = true;
      this.$log.debug({ printData, selectedPrintData, value });
      this.printData[selectedPrintData] = value;
      await this.updatePrintData();
      this.$forceUpdate();
      this.isLoading = false;
    },
    async updatePrintData() {
      this.$log.debug('updating print data');
      const { jobListingCandidate, printData } = this;
      const { id } = jobListingCandidate;
      printData.templateId = this.templateId;
      await this.UPDATE_jobListingCandidate({
        id,
        printData: JSON.stringify(printData),
      });
    },
    async updateCandidate(candidate, field, value) {
      this.$log.debug({ candidate, field, value });
      const updatedCandidate = {
        id: candidate.id,
        [field]: value,
      };
      await this.UPDATE_candidate(updatedCandidate);
      this.$forceUpdate();
    },
  },
};
</script>
