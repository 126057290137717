<template>
  <v-form
    lazy-validation
    v-model="valid"
    @submit.prevent="submit"
    ref="form"
  >
    <v-container class="text-center">
      <ProgressLinearTimeout v-if="isLoading" />
      <v-row>
        <v-col cols="12">
          <v-select
            :items="jobListingStatuses"
            item-text="name"
            item-value="name"
            filled
            required
            data-cy="FormJobListing-status"
            :label="$$t('status')"
            v-model="localJobListing.status"
            :rules="[rules.required]"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            filled
            required
            data-cy="FormJobListing-name"
            :label="$$t('name')"
            v-model="localJobListing.name"
            :rules="[rules.required]"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            filled
            data-cy="FormJobListing-projectNumber"
            :label="$$t('projectNumber')"
            v-model="localJobListing.projectNumber"
            :rules="[rules.required]"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-autocomplete
            v-model="localJobListing.responsibleUserId"
            filled
            data-cy="FormJobListing-responsibleUserId"
            :label="$$t('responsibleUserId')"
            :items="sortedUsers"
            item-text="userName"
            item-value="userName"
            :rules="[rules.required]"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-autocomplete
            data-cy="FormJobListing-clientId"
            filled
            :items="availableCompanies"
            item-text="name"
            item-value="id"
            v-model="selectedClientId"
            :label="$$t('contact')"
            :rules="[rules.required]"
          >
            <template v-slot:prepend-inner>
              <v-btn
                text
                small
                href="https://app.hubspot.com/contacts/1883769/objects/0-2/views/all/list"
                target="_blank"
                rel="noopener noreferrer"
              >
                Add
              </v-btn>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-icon>
                <v-icon v-if="item.type === 'Client'">{{ mdiOfficeBuilding }}</v-icon>
                <v-icon v-else-if="item.type === 'HubSpot'">{{ mdiHubspot }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                {{ item.name }}
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            filled
            data-cy="FormJobListing-description"
            :label="$$t('description')"
            v-model="localJobListing.description"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-select
            filled
            :items="industries"
            item-text="name"
            item-value="id"
            v-model="localJobListing.industryId"
            :label="$$t('industryId')"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <SelectTeam v-model="localJobListing.teamId" />
        </v-col>
      </v-row>
      <v-row v-if="localJobListing.teamId">
        <v-col cols="12">
          <SelectTeamChannel
            :team-id="localJobListing.teamId"
            v-model="localJobListing.teamChannelId"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-textarea
            filled
            data-cy="FormJobListing-notes"
            :label="$$t('notes')"
            v-model="localJobListing.notes"
          />
        </v-col>
      </v-row>
      <div class="d-flex justify-space-between mb-6">
        <v-btn
          color="default"
          outlined
          class="btn-cancel"
          @click="cancel"
          data-cy="FormJobListing-button-cancel">
          {{ $t('common.buttons.cancel') }}
        </v-btn>
        <v-checkbox
          v-if="mode === 'add'"
          v-model="localAddToFavourites"
          @change="$emit('update:addToFavourites', $event)"
          label="Add to favourites"
        />
        <v-btn
          color="primary"
          type="submit"
          class="ml-4"
          :loading="isSaving"
          data-cy="FormJobListing-button-save">
          {{ $t('common.buttons.save') }}
        </v-btn>
      </div>
    </v-container>
  </v-form>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { sortBy } from 'lodash';
import { mdiOfficeBuilding, mdiHubspot } from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import validation from '@codehq/aurora-app-core/src/components/validation';
import ProgressLinearTimeout from '@codehq/aurora-app-core/src/components/ProgressLinearTimeout.vue';
import SelectTeam from '../../../components/SelectTeam.vue';
import SelectTeamChannel from '../../../components/SelectTeamChannel.vue';

export default {
  name: 'FormJobListing',
  module: 'JobListings',
  components: {
    ProgressLinearTimeout,
    SelectTeam,
    SelectTeamChannel,
  },
  mixins: [loading, validation],
  props: {
    /**
    * Set the text box value. Default: undefined,
    */
    JobListing: {
      type: Object,
      default: undefined,
    },
    addToFavourites: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: 'add',
    },
  },
  data() {
    return {
      dialog: false,
      mdiHubspot,
      mdiOfficeBuilding,
      editClient: {},

      selectedClientId: undefined,
      /**
      * Hold form validation state
      */
      valid: false,
      /**
      * Local value used for property localJobListing
      */
      localJobListing: {},
      localAddToFavourites: false,
      isSavingClient: false,
      users: [],
    };
  },
  computed: {
    ...mapState('clients', ['clients']),
    ...mapState('hubSpotCompanies', ['hubSpotCompanies']),
    ...mapState('jobListingStatuses', ['jobListingStatuses']),
    ...mapState('industries', ['industries']),
    availableCompanies() {
      const { clients, hubSpotCompanies } = this;
      const availableClients = clients
        .filter((c) => !hubSpotCompanies.find((hc) => hc.hsObjectId === c.HubSpotId));

      const result = [
        ...sortBy(availableClients, 'name')
          .map((a) => ({ name: a.name, id: a.id, type: 'Client' })),
        ...sortBy(hubSpotCompanies, 'name')
          .map((a) => ({ name: a.name, id: a.hsObjectId, type: 'HubSpot' })),
      ];
      return result;
    },
    sortedUsers() {
      const { users } = this;
      return users.sort((a, b) => {
        if (a.userName < b.userName) {
          return -1;
        }
        if (a.userName > b.userName) {
          return 1;
        }
        return 0;
      });
    },
  },
  watch: {
    JobListing() {
      this.localJobListing = this.JobListing;
    },
    addToFavourites() {
      this.localAddToFavourites = this.addToFavourites;
    },
  },
  async mounted() {
    await this.refresh();
  },
  methods: {
    ...mapActions('clients', ['LOAD_clients', 'CREATE_client']),
    ...mapActions('hubSpotCompanies', ['LOAD_hubSpotCompanies']),
    ...mapActions('jobListingStatuses', ['LOAD_jobListingStatuses']),
    ...mapActions('industries', ['LOAD_industries']),
    async refresh() {
      this.isLoading = true;
      await Promise.all([
        (async () => {
          const { data } = await this.$http.get('api/users');
          this.users = data;
        })(),
        (async () => {
          await this.LOAD_jobListingStatuses();
        })(),
        (async () => {
          await this.LOAD_industries();
        })(),
        (async () => {
          await this.LOAD_hubSpotCompanies();
        })(),
      ]);
      this.localJobListing = this.JobListing;
      this.selectedClientId = this.JobListing.clientId;
      this.localAddToFavourites = this.addToFavourites;
      this.isLoading = false;
    },
    async saveClient(client) {
      this.isSavingClient = true;
      await this.CREATE_client(client);
      this.isSavingClient = false;
      await this.LOAD_clients();
      this.dialog = false;
    },
    /** Submit Form data trigger
     * @event save
     * @type {object}
     */
    async submit() {
      // validate
      if (this.$refs.form.validate()) {
        const client = this.availableCompanies.find((c) => c.id === this.selectedClientId);
        if (client.type === 'HubSpot') {
          const hubSpotCompany = this.hubSpotCompanies
            .find((c) => c.hsObjectId === this.selectedClientId);

          const createdClient = await this.CREATE_client({
            name: hubSpotCompany.name,
            hubSpotId: hubSpotCompany.hsObjectId,
            website: hubSpotCompany.domain,
          });
          this.localJobListing.clientId = createdClient.id;
        } else {
          this.localJobListing.clientId = this.selectedClientId;
        }
        this.$emit('save', this.localJobListing);
        this.isSaving = true;
      }
    },
    /** Cancel form trigger
     * @event cancel
     */
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<docs>
# FormJobListing example

'''js
<FormJobListing :JobListing="JobListing" />
'''
</docs>
